
import Vue, { defineComponent } from 'vue'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import Lead from '@/store/Models/Lead'

import FmlQuestionSet from '@/FML/views/FmlQuestionSet.vue'
import QLeadLookup from '@/components/utils/QLeadLookup.vue'
import QCreateLeadReferral from '@/components/utils/QCreateLeadReferral.vue'
import FIFDirectToReset from './FIFDirectToReset.vue'
import FIFProcessResults from './FIFProcessResults.vue'
import { FifScript } from '@/views/NewBusiness/FIF/FIFSurvey/FifScripts'

export default defineComponent ({
  	components: {
		FmlQuestionSet,
		QLeadLookup,
		QCreateLeadReferral,
		FIFProcessResults,
		FIFDirectToReset,
	},
	props: {
		leadCode: { type: String },
		formResponseId: { type: Number },
	},
    data () {
        return {
            response: null as any,
            events: [],
            step: 0,
            lead: null as null | ReturnType<typeof Lead.fields>,
            loading: false,
            create_referral_dialog: false,
            licensing: null,
            show_response: false,
            the_form: null,
            loaded: false,
            slug: 'financial-information-form',
            show_script: false,
            showDirectToReset: false,
            directToResetEventBus: new Vue(),
            DFLSME: null,
			onFmlSaveMessage: '',
			questionSetsLoaded: {
				set1: false,
				set2: false,
				set3: false,
				set4: false,
			},
        }
    },
    mounted () {
        if (this.$route.query?.lead_code || this.leadCode) {
			const leadCode = this.$route.query?.lead_code || this.leadCode
            this.findLead(leadCode)
        }
        this.loadLicensing()
        this.loadForm(this.slug)
    },
    computed: {
        agent_code () {
            if (this.response?.AgentCode) {
                return this.response.AgentCode
            } else {
                return this.user.AgentCode
            }
        },
		expenses () {
			return {
				'Mortgage Payments': this.getResponseSum('Mortgage Information', null, 'Payment'),
				'Insurance Payments': this.getResponseSum('Insurance Information', null, 'Premium'),
				'Savings': this.getResponseSum('Additional Assets','Savings', 'Employee Contribution'),
				'Other Monthly Expenses': this.getResponseSum('Monthly Expenses',null, 'Payment'),
			}
		},
		expenses_total (): number {
			return Object.values(this.expenses).reduce((acc, value) => acc + + value, 0)
		},
	  	// TODO Totals use pretty much the same logic
        income_1_total (): number {
            // grabs all repeatable fields to get the question's answers and tallies them.
		  	// TODO Should use alias or something similar
            const question_ids = {
                8: 'field_1559' //question ID : answer field name
            }
            let t = 0
            Object.keys(question_ids).forEach(id => {
                if (this.response == null || typeof this.response.FormResponseAnswers == 'undefined' || this.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                this.response.FormResponseAnswers[id].AnswerContent.forEach(answer => {
                    t = t + Number(answer[question_ids[id]].AnswerContent)
                })
            })
            return t
        },
        income_2_total (): number {
            // grabs all repeatable fields to get the question's answers and tallies them.
		    const question_ids = {
                10: 'field_1542' //question ID : answer field name
            }
            let t = 0
            Object.keys(question_ids).forEach(id => {
                if (this.response == null || typeof this.response.FormResponseAnswers == 'undefined' || this.response.FormResponseAnswers[id].AnswerContent == null) {
                    return
                }
                this.response.FormResponseAnswers[id].AnswerContent.forEach(answer => {
                    t = t + Number(answer[question_ids[id]].AnswerContent)
                })
            })
            return t
        },
        income_total (): number {
            return this.income_1_total + this.income_2_total
        },
        assets_total (): number {
		  	// grabs all repeatable fields to get the question's answers and tallies them.
            const question_ids = {
                6: 'field_4665' //question ID : answer field name
            }
            let value = 0

			Object.keys(question_ids).forEach(id => {
                if (!this?.response?.FormResponseAnswers?.[id]?.AnswerContent) {
                    return
                }
                this.response.FormResponseAnswers[id].AnswerContent.forEach(answer => {
                    value = value + Number(answer[question_ids[id]].AnswerContent)
                })
            })
			return value
        },
		employeeContribution() {
            const assetsQuestionId = 6
            const employeeField = 'field_4667'

            return this.response?.FormResponseAnswers?.[assetsQuestionId].AnswerContent?.reduce((acc, answer) => {
                return acc + Number(answer[employeeField].AnswerContent)
            }, 0) || 0;
		},
		employerContribution() {
            const assetsQuestionId = 6
            const employerField = 'field_4668'

            return this.response?.FormResponseAnswers?.[assetsQuestionId].AnswerContent?.reduce((acc, answer) => {
                return acc + Number(answer[employerField].AnswerContent)
            }, 0) || 0;
		},
        employee_contribution_overage (): number {
            return this.employeeContribution - this.employerContribution
        },
		monthlyExpensesDebtCount() {
            const monthlyQuestionId = 7
            const typeField = 'field_4290'
            const paymentField = 'field_4291'

			const monthlyExpensesDebts = this.response?.FormResponseAnswers?.[monthlyQuestionId].AnswerContent?.filter((answer) => {
				const type = answer[typeField].AnswerContent
				const payment = Number(answer[paymentField].AnswerContent)

				return payment > 0 && type && !["Utilities", "Other Insurances"].includes(type)
			}) || []

            return monthlyExpensesDebts.length
		},
		overpayment_count () {
			// TODO Should use aliases
			const questions_map = {
				4: 'field_6269', // Mortgage Information -> overpayment
				7: 'field_4716', // Monthly Expenses -> overpayment
				11: 'field_4716', // Debt TODO That field seems to be missing from FIF
			}
			let count = 0
			Object.keys(questions_map).forEach(id => {
				if (!this.response?.FormResponseAnswers?.[id]?.AnswerContent) {
					return
				}
				const itemId = questions_map[id]
				this.response.FormResponseAnswers[id].AnswerContent.forEach(items => {
					// TODO Do we need "true" check?
					if (items[itemId].AnswerContent) {
						count++
					}
				})
			})
			return count
		},
		income_after_expenses (): number {
			return this.income_total - this.expenses_total
		},
        processResultsScripts (): Record<FifScript, boolean> {
        	// See: https://quility.atlassian.net/jira/software/projects/DEVST/boards/12?selectedIssue=DEVST-1695
			const scripts: Record<FifScript, boolean> = {
				[FifScript.DFL]: false,
				[FifScript.QRS]: false,
				[FifScript.IUL]: false,
				[FifScript.Annuity]: false,
			}
			if (
				this.lead?.Age < 50
				&& !this.employerContribution
				&& this.employeeContribution > 0
				&& this.monthlyExpensesDebtCount < 2
				&& this.assets_total < 100000
			) {
				scripts[FifScript.IUL] = true
			}
            if (!this.employerContribution
				&& !this.employeeContribution) {
					if (this.assets_total >= 200000 && !this.user.Agent.AnnuitySME) {
						scripts[FifScript.QRS] = true
					} else if (this.assets_total >= 100000) {
						scripts[FifScript.Annuity] = true
					}
            }
            if (this.overpayment_count || !Object.values(scripts).some(i => i)) {
                // Show DFL by default
				scripts[FifScript.DFL] = true
            }
			return scripts
        },
		allLoaded (): boolean {
			const loaded = !this.loading
			const formLoaded = !!this.the_form
			const leadLoaded = (this.$route.query?.lead_code || this.leadCode) ? !!this.lead : true
			const questionSetsLoaded = Object.entries(this.questionSetsLoaded)
				.filter(([key, value]) => (key === 'set4' && !this.hasRole('SuperAdmin')) ? false : true)
				.map(([key, value]) => value)
				.every(Boolean)
			return [loaded, formLoaded, leadLoaded, questionSetsLoaded].every(Boolean)
		},
    },
    watch: {
		allLoaded (newVal) {
			if (newVal) {
				this.$emit('loaded', this.the_form)
			}
		},
        'response.Lead.LeadCode' () {
            this.$nextTick(() => {
                this.lead = this.response.Lead
            })
        },
        lead (newV, oldV) {
            if (!newV){ return }
            if (oldV) {
                if (newV.LeadCode === oldV.LeadCode){
                    // this is the same lead.
                    return
                }
                this.triggerAutoSaving()
            }
            if (newV.LeadAssignmentID === this.response.LeadAssignmentID){
                return
            }
            if (this.$refs.assets_section.loadedQuestionSet) {
                this.getLeadFormResponse(newV.LeadCode, newV.LeadAssignmentID, this.$refs.assets_section.loadedQuestionSet.FormQuestions[0].ID)
            }
        },
        '$route.query.form_tab' (newV, oldV) {
            this.step = newV
        },
        '$route.query.lead_code' (newV) {
            if (newV) {
				this.findLead(newV)
            }
        },
        leadCode (newV) {
            if (newV) {
				this.findLead(newV)
            }
        },
        '$route.query.form_response_id' (newV) {
            // we want to load a specific response id...
            if (typeof newV != 'undefined' && newV != null) {
                // this.$refs.assets_section.loadFormResponse(newV);
                return
            }
            //this.$refs.assets_section.loadFormResponseTemplate('new');
        },
        formResponseId (newV) {
            // we want to load a specific response id...
            if (typeof newV != 'undefined' && newV != null) {
                // this.$refs.assets_section.loadFormResponse(newV);
                return
            }
            // this.$refs.assets_section.loadFormResponseTemplate('new');
        },
        // step (newV) {
        //     setTimeout(() => {
        //         this.$vuetify.goTo('#panel' + newV, {
        //             duration: 500,
        //             easing: 'easeOutCubic',
        //             offset: 150
        //         })
        //     }, 250)
        // }
    },
    beforeRouteLeave(to, from, next) {
        this.triggerAutoSaving()
        next()
    },
    methods: {
		setQuestionSetLoaded (setIndex, data) {
			if (data) {
				this.questionSetsLoaded[`set${setIndex}`] = true
			}
		},
		onScriptFinished (script: FifScript) {
			// this.response.FormResponseAnswers[255].AnswerContent = 'QRS Team'

			const resetTypeAnswer: any = Object.values(this.response.FormResponseAnswers)
				.find(item => item.FormQuestionTitle === 'Reset Type')
			// this.$set(this.response, "LeadAssignmentID", lead_assignment_id);

			// this.response.FormResponseAnswers[255].AnswerContent = 'QRS Team'
			resetTypeAnswer.AnswerContent = script
		},
    	// need to keep in mind if the question title changes we need to update the code.
		getResponseSum (questionTitle, answerContentValue, valueTitle) {
			let result = 0
			if (!this.response?.FormResponseAnswers) {
				return result
			}

			const additionalAssets = Object.values(this.response.FormResponseAnswers)
				.find(answer => answer.FormQuestionTitle === questionTitle)


			if (!additionalAssets?.AnswerContent) {
				return result
			}

			additionalAssets.AnswerContent.forEach(answerContent => {
		  		const answerItems = Object.values(answerContent)
				if (answerContentValue && answerItems[0].AnswerContent !== answerContentValue /* Type of Asset */ ) {
					return
				}
				const valueItem = answerItems.find(item => item.Title === valueTitle)
				result += + valueItem.AnswerContent // Current Value
			})

			return result
		},
        nextStep (data, autoSaving = false) {
            if (autoSaving) { return }
            if (this.step == 3) { return }
            if (typeof data === 'object') {
                this.step = this.step + 1
            } else {
                this.step = data
            }
        },
        logEvent (e) {
            this.events.push(e)
        },
        submittedEvent () {
            this.loading = false
            this.show_response = true
        },
        loadForm (slug: string) {
			this.loaded = false
            this.the_form = null
			const response_id = this.$route.query.form_response_id
				? this.$route.query.form_response_id
				: this.formResponseId || 'new'
            QuilityAPI.getFormWithSlug(slug, response_id)
                .then((data) => {
                    this.the_form = data
                    this.$forceUpdate()
                    this.$nextTick(() => {
						this.loaded = true
					})
                })
                .catch((err) => {
                    this.showError("Whoops! Can't find that form!<br>", err.message)
					this.$emit('error')
                })
            // this.hasChanges = false
        },
        getLeadFormResponse (leadCode, lead_assignment_id, form_question_id) {
            this.loading = true
            QuilityAPI.getLeadFormResponse(form_question_id, leadCode, this.agent_code)
				.then((json) => {
					this.loading = false
					this.$set(this, 'response', json.data)
					this.$set(this.response, 'LeadAssignmentID', lead_assignment_id)
					this.$emit('loaded', json.data)
					this.step = 1
				})
				.catch(() => {
					this.loading = false
					this.$emit('error')
				})
        },
        findLead (leadCode: string) {
            this.step = 0
            this.loading = true
            // Lazily load input items
            QuilityAPI.getLead(leadCode, this.agent_code)
				.then((json) => {
					this.$set(this, 'lead', json)
					this.$set(this.response, 'LeadAssignmentID', json.ID)
					this.loading = false
				})
				.catch(() => {
					this.loading = false
					// this.$emit('error')
				})
        },
        loadLicensing () {
            if (this.user.Agent.AgentCode === 'SFG0000001') {
                this.$set(this, 'licensing', [])
                return
            }
            QuilityAPI.getAgentLicensing(this.user.Agent, this.role)
				.then((resp) => {
					if (resp.data && resp.data.licenses) {
						this.$set(this, 'licensing', resp.data.licenses)
					} else {
						this.showError("Could not load this agent's licensing information!<br>")
					}
				})
        },
        setReferralLead (lead) {
            this.lead = lead
        },
        sendEmail () {
            this.loading = true
            if (this.$refs.assets_section.loadedQuestionSet) {
                this.response.SubmittedInd = true
                this.$nextTick(() => this.$refs.assets_section.saveResponse())
            }
        },
        onSMEConfirm (e) {
            if (('Agent' in e) && ('AgentCode' in e)) {
                // DFL Fields
                this.response.FormResponseAnswers[64].AnswerContent = e.Agent
                this.response.FormResponseAnswers[63].AnswerContent = e.AgentCode
                // Dev environment
                // this.response.FormResponseAnswers[277].AnswerContent = e.Agent
                // this.response.FormResponseAnswers[278].AnswerContent = e.AgentCode

                this.DFLSME = e

                this.response.SubmittedInd = true
                this.$nextTick(() => this.$refs.hidden_section.saveResponse())
                this.closeDialog()

                // loading get's set to false when this.$refs.hidden_section emits its 'saved' event
                this.loading = true
				this.onFmlSaveMessage = 'Your appointment has been set.'
            }
        },
        onQRSConfirm (e) {
            // QRS Fields
            this.response.FormResponseAnswers[64].AnswerContent = 'QRS Team'
            this.response.FormResponseAnswers[63].AnswerContent = 'QRS Team'
            this.response.SubmittedInd = true
            // Dev environment
            // this.response.FormResponseAnswers[277].AnswerContent = 'QRS Team'
            // this.response.FormResponseAnswers[278].AnswerContent = 'QRS Team'

            this.$nextTick(() => this.$refs.hidden_section.saveResponse())
            this.closeDialog()

                // loading get's set to false when this.$refs.hidden_section emits its 'saved' event
            this.loading = true
			this.onFmlSaveMessage = 'Your appointment has been set.'
        },
		closeDialog () {
			if (this.show_script) {
				this.show_script = false
			}
			if (this.showDirectToReset) {
				this.closeDirectToReset()
			}
		},
		closeDirectToReset () {
			this.showDirectToReset = false
			this.directToResetEventBus.$emit('clear-form-select')
		},
		onFmlSave () {
			this.loading = false
			if (this.onFmlSaveMessage) {
				this.showSuccess(this.onFmlSaveMessage)
				this.onFmlSaveMessage = ''
			}
		},
        triggerAutoSaving () {
            if (this.$refs.assets_section) {
                this.$refs.assets_section.triggerAutoSaving()
            } else if (this.$refs.income_section) {
                this.$refs.income_section.triggerAutoSaving()
            } else {
                this.$refs.notes_section?.triggerAutoSaving()
            }
        },
    },
})
